import React, { useState } from "react"
import { Container, Table, Accordion, Icon } from "semantic-ui-react"

import Layout from "../components/layout"
import SEO from "../components/Seo"
import Header from "../components/Header"
import Decoration from "../components/Decoration"

const FaqPage = () => {
  const [activeIndex, setActiveIndex] = useState(false)

  const handleClick = (e, titleProps) => {
    if (activeIndex === titleProps.index) {
      setActiveIndex(false)
      return
    }

    setActiveIndex(titleProps.index)
  }

  return (
    <Layout id="pages-dark">
      <SEO title="FAQ" description="All the frequently asked questions from our playerbase compiled into a simple list" />
      <Header active="faq" />
      <Container text>
        <Decoration content="FAQ" />
        <Accordion fluid styled>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
              What is SlashDiablo?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <p>
              SlashDiablo is a privately operated and community fed Diablo 2 server. Started as a server for friends then opened up to others many years ago, SlashDiablo has grown in to an amazing community which spans over many online games, the main one being Diablo 2 Lord of Destruction.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 1}
            index={1}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            What is the Armory?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            <p>
              Our armory is another Quality of life feature for our players. You can view the current ladder as well as previous ladder standings. You can also lookup and search all the Slash characters and get a detailed view of their skills, items, stats and mercenary/golem. It makes it very easy to share character builds or mule items for trade and such. You can find it <a href="https://armory.slashdiablo.net/" rel="noopener noreferrer" target="_blank" alt="Armory website">here</a>.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 2}
            index={2}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            When will the ladder reset?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 2}>
            <p>
              Our ladders reset around once every 4 months. At those times, Ladder charecters will all be moved to non-ladder so that everyone on ladder can start fresh all at once. No characters will be deleted. Ladder reset dates will be announced on the site as well as our Discord.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 3}
            index={3}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            Are we allowed to bot?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 3}>
            <p>
              No botting of any kind. This includes other automated processes such as pickit, auto-tele, chicken, etc. If you are botting, all your accounts will be removed and you will be permanently banned from our community.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 4}
            index={4}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            Do characters get deleted on here from lack of use?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 4}>
            <p>
              No. Unlike, battle.net, characters do not expire on the private realm. New characters created do not have to "idle" to be made permanent.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 5}
            index={5}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            How does everyone communicate and trade on SlashDiablo?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 5}>
            <p>
              Most of our players communicate using the In-Game Global Chat feature. We also highly recommend joining our Discord by <a href="https://discord.gg/0Xeb4YQwnUVhxisY">Clicking here</a>.
            </p>

            <p>To use In-Game Global Chat, join a game and use the following commands:</p>

            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Action</Table.HeaderCell>
                  <Table.HeaderCell>Command</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Subscribe</Table.Cell>
                  <Table.Cell>
                    {"//"}sub chat<br />
                    {"//"}sub trade<br />
                    {"//"}sub hc
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Send message</Table.Cell>
                  <Table.Cell>
                    {"//"}chat Hello SC<br />
                    {"//"}trade Ber rune FT<br />
                    {"//"}hc Hello HC
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Unsubscribe</Table.Cell>
                  <Table.Cell>
                    {"//"}unsub chat<br />
                    {"//"}unsub trade<br />
                    {"//"}unsub hc
                    </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 6}
            index={6}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            How do I choose which game server to play on?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 6}>
            <p>
              Simply add one of the gsX into the game description field when creating a game. You will know you are on the correct server because of the In-Game welcome message when you first join a game. Using our Slash BH Maphack, It will remember the game description field, so no need to type it in everytime. By using Slash BH, The game list in-game will display each games game server and difficulty. Note: Leaving the game description field blank will default you to GS1. Read more <a href="/#game-servers" title="Game servers">here</a>.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 7}
            index={7}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            How do I launch multiple boxes?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 7}>
            <p>
              In the SlashDiablo Launcher click the settings icon on the top right, then change 'instances to launch' to your desired amount of boxes. Note You can only have up to 4 connections at once to SlashDiablo.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 8}
            index={8}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            Can we share accounts?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 8}>
            <p>
              Sharing accounts is allowed. However, the entire account will still be banned if any user on it bots or breaks the rules.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 9}
            index={9}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            Are you guys on ladder or non-ladder?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 9}>
            <p>
              There are a few non-ladder players, but a vast majority play ladder. It is worth noting that all ladder runewords and items can be made on non-ladder.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 10}
            index={10}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            Hardcore or softcore?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 10}>
            <p>
              Both Softcore and Hardcore have a good community of players in them, so choose whichever one you fancy, or do it all. Our Hardcore community mostly communicates via our Discord instead of Global chat.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 11}
            index={11}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            What about classic?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 11}>
            <p>
              There are a small group of players who play classic as well, but not as much.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 12}
            index={12}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            What character build should I play around here?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 12}>
            <p>
              Generally since we're such a close server to default, pretty much all regular diablo 2 builds work on this server. That means if you google "sorceress build diablo 2", the builds that pop up will work just fine on SlashDiablo.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 13}
            index={13}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            Do I need to install Diablo II and Diablo II Lord of Destruction before I can play this mod?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 13}>
            <p>
              Yes. Players are required to have both games installed before installing SlashDiablo.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 14}
            index={14}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            What version of Diablo II do I need before I can play on SlashDiablo?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 14}>
            <p>
              Any version, our installer will revert you back to Diablo II LoD v1.13c
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 15}
            index={15}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            Can I still play SlashDiablo without my CD Keys?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 15}>
            <p>
              Our private server does not check for CD Keys but you need some keys to install Diablo 2 and LoD. Installs should be made using a valid CD Key from Blizzard Entertainment. We do not support piracy.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 16}
            index={16}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            I want to play both Battle.net and SlashDiablo, is this possible?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 16}>
            <p>
              Yes. Prior to installing Diablo SlashDiablo simply copy and paste your Diablo II folder in Program Files and rename it to SlashDiablo. Select this SlashDiablo folder as your directory path in the SlashDiablo Launcher.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 17}
            index={17}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            Can I play SlashDiablo work on Mac or Linux?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 17}>
            <p>
              Mac and Linux are not officially supported with the Launcher, however we do have a couple of guides on how to install on those platforms via Wine.
              <ul>
                <li><a rel="noopener noreferrer" target="_blank" href="https://github.com/SlashFap/SlashDiabloOnLinux/wiki/SlashDiablo-on-Linux-:-Diablo-II-setup">Linux install guide</a></li>
                <li><a rel="noopener noreferrer" target="_blank" href="https://www.reddit.com/r/slashdiablo/comments/58jify/diablo_2_lod_on_mac_20_includes_wineskin_tabbed/">Mac install guide</a></li>
              </ul>

              Some have also successfully used the Linux guide on mac, with a couple of command line changes.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 18}
            index={18}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            I keep receiving a c000005 error, what should I do?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 18}>
            <p>
              In the SlashDiablo Launcher, click settings on the top right, then click the correct directory for your SlashDiablo install, then click "Run" on Disable DEP. Restart your PC. Sometimes multiple restarts are required.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 19}
            index={19}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            My map reveal isn't working even though I have it selected in the SlashDiablo Launcher settings, what should I do?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 19}>
            <p>
              Download and Install <a href="https://aka.ms/vs/16/release/vc_redist.x86.exe" rel="noopener noreferrer" target="_blank">Microsoft Visual C++ Redistributable x86 (2015-2019)</a>.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 20}
            index={20}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            When I shift + click things my Diablo 2 window errors out, what do I do?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 20}>
            <p>
              In the SlashDiablo Launcher, increase the launch delay above the play icon to a higher amount of seconds, restart all D2 instances.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 21}
            index={21}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            Monsters are not dropping any loot or loot isnt being filtered on the ground, what's going on?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 21}>
            <p>
              Our newest maphack has 'filter level' and notification ping tiers feature. CTRL + Left click the "BH X.X.X" box visible in game. Select the 'Item' tab up top, then select the filter level and ping level you desire. More information on this is available <a href="https://github.com/youbetterdont/bhconfig/wiki/User-Guide" target="_blank" rel="noopener noreferrer">here</a>. Filter level corresponds to the items you see on the ground, 0 filter level wont filter anything on the ground. The higher the ping tiers the more notifications you will get on the top left when items drop.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 22}
            index={22}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            How do I edit the loot filter so items look the way I want?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 22}>
            <p>
              You will want to edit the BH.cfg file and turn on the "config override" button in the SlashDiablo launcher. Here's a <a href="https://github.com/youbetterdont/bhconfig/wiki/How-to-edit-the-config" rel="noopener noreferrer" target="_blank" alt="Edit loot filter">guide</a> on how to edit the config correctly.
            </p>
            <p>Other sources on how to edit are listed below. If you're still having trouble join our <a href="https://discord.gg/0Xeb4YQwnUVhxisY" rel="noopener noreferrer" target="_blank" alt="Discord community invite">Discord</a> and ask for help in the #maphack-support channel.

            <ul>
                <li><a href="https://github.com/planqi/slashdiablo-maphack/wiki" rel="noopener noreferrer" target="_blank" alt="Maphack wiki">Maphack wiki</a></li>
                <li><a href="https://github.com/underbent/slashdiablo-maphack/wiki/Item-Display" rel="noopener noreferrer" target="_blank" alt="Maphack wiki">Item display guide</a></li>
              </ul>
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 23}
            index={23}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            Whenever I click esc, options, video options, my game errors out, how do I fix this?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 23}>
            <p>
              Download and run <a href="https://cdn.discordapp.com/attachments/115454319993683973/738091174065078431/Repair_Options_Crash.bat" alt="Repair crash bat download">this file</a> as admin to fix video options.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 24}
            index={24}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            I'm still having trouble installing/getting SlashDiablo to work correctly, what can I do?
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 24}>
            <p>
              Join our Discord and ask for help in the #help-and-support channel. There's always someone available to help out. <a href="https://discord.gg/0Xeb4YQwnUVhxisY" rel="noopener noreferrer" target="_blank" alt="Discord community invite">Click here</a> to join our Discord community
            </p>
          </Accordion.Content>
        </Accordion>
      </Container>
    </Layout >
  )
}

export default FaqPage
